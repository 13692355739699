

import request from "@/utils/request.js";



// GET /api/Wechat/GetYuJing
// 根据区域返回预警
// "/api/Wechat/GetYuJing"
export function GetYuJing(params) {
  return request({
    url: "/api/Wechat/GetYuJing",
    method: "GET",
    params
  });
}

// GET /api/Wechat/tqybdata   彩云天气预报+实况
export function tqybdata(params) {
  return request({
    url: "/api/Wechat/tqybdata",
    method: "GET",
    params
  });
}

// /api/Wechat/tqybdatas?lat=34.25&lng=116.25
export function tqybdatas(params) {
  return request({
    url: "/api/Wechat/tqybdatas",
    method: "GET",
    params
  });
}

// http://www.nmc.cn/rest/weather?stationid=53996&_=1688375559820
export function tqybweather1(params) {
    // return request({
    //   url: "http://www.nmc.cn/rest/weather",
    //   method: "GET",
    //   params
    // });
  let data = {
  "msg": "success",
  "code": 0,
  "data": {
    "real": {
      "station": {
        "code": "53996",
        "province": "河北省",
        "city": "成安",
        "url": "/publish/forecast/AHE/chengan.html"
      },
      "publish_time": "2023-07-04 09:35",
      "weather": {
        "temperature": 27.2,
        "temperatureDiff": -2.5,
        "airpressure": 9999,
        "humidity": 76,
        "rain": 0,
        "rcomfort": 73,
        "icomfort": 1,
        "info": "多云",
        "img": "1",
        "feelst": 26.2
      },
      "wind": {
        "direct": "西北风",
        "degree": 343,
        "power": "微风",
        "speed": 1.9
      },
      "warn": {
            "alert": "9999",
            "pic": "9999",
            "province": "9999",
            "city": "9999",
            "url": "9999",
            "issuecontent": "9999",
            "fmeans": "9999",
            "signaltype": "9999",
            "signallevel": "9999",
            "pic2": "9999"
      }
    },
    "predict": {
      "station": {
        "code": "53996",
        "province": "河北省",
        "city": "成安",
        "url": "/publish/forecast/AHE/chengan.html"
      },
      "publish_time": "2023-07-04 08:00",
      "detail": [
        {
          "date": "2023-07-04",
          "pt": "2023-07-04 08:00",
          "day": {
            "weather": {
              "info": "多云",
              "img": "1",
              "temperature": "35"
            },
            "wind": {
              "direct": "北风",
              "power": "微风"
            }
          },
          "night": {
            "weather": {
              "info": "晴",
              "img": "0",
              "temperature": "22"
            },
            "wind": {
              "direct": "西南风",
              "power": "微风"
            }
          }
        },
        {
          "date": "2023-07-05",
          "pt": "2023-07-04 08:00",
          "day": {
            "weather": {
              "info": "晴",
              "img": "0",
              "temperature": "40"
            },
            "wind": {
              "direct": "西风",
              "power": "微风"
            }
          },
          "night": {
            "weather": {
              "info": "晴",
              "img": "0",
              "temperature": "24"
            },
            "wind": {
              "direct": "西南风",
              "power": "微风"
            }
          }
        },
        {
          "date": "2023-07-06",
          "pt": "2023-07-04 08:00",
          "day": {
            "weather": {
              "info": "晴",
              "img": "0",
              "temperature": "41"
            },
            "wind": {
              "direct": "南风",
              "power": "微风"
            }
          },
          "night": {
            "weather": {
              "info": "晴",
              "img": "0",
              "temperature": "25"
            },
            "wind": {
              "direct": "南风",
              "power": "微风"
            }
          }
        },
        {
          "date": "2023-07-07",
          "pt": "2023-07-04 08:00",
          "day": {
            "weather": {
              "info": "多云",
              "img": "1",
              "temperature": "40"
            },
            "wind": {
              "direct": "东北风",
              "power": "微风"
            }
          },
          "night": {
            "weather": {
              "info": "晴",
              "img": "0",
              "temperature": "24"
            },
            "wind": {
              "direct": "西北风",
              "power": "微风"
            }
          }
        },
        {
          "date": "2023-07-08",
          "pt": "2023-07-04 08:00",
          "day": {
            "weather": {
              "info": "多云",
              "img": "1",
              "temperature": "37"
            },
            "wind": {
              "direct": "东风",
              "power": "微风"
            }
          },
          "night": {
            "weather": {
              "info": "晴",
              "img": "0",
              "temperature": "24"
            },
            "wind": {
              "direct": "东风",
              "power": "微风"
            }
          }
        },
        {
          "date": "2023-07-09",
          "pt": "2023-07-04 08:00",
          "day": {
            "weather": {
              "info": "多云",
              "img": "1",
              "temperature": "37"
            },
            "wind": {
              "direct": "东风",
              "power": "微风"
            }
          },
          "night": {
            "weather": {
              "info": "多云",
              "img": "1",
              "temperature": "24"
            },
            "wind": {
              "direct": "东风",
              "power": "微风"
            }
          }
        },
        {
          "date": "2023-07-10",
          "pt": "2023-07-04 08:00",
          "day": {
            "weather": {
              "info": "小雨",
              "img": "7",
              "temperature": "38"
            },
            "wind": {
              "direct": "东南风",
              "power": "微风"
            }
          },
          "night": {
            "weather": {
              "info": "中雨",
              "img": "8",
              "temperature": "24"
            },
            "wind": {
              "direct": "东南风",
              "power": "微风"
            }
          }
        }
      ]
    },
    "air": {
      "forecasttime": "2023-07-04 09:00",
      "aqi": 30,
      "aq": 1,
      "text": "优",
      "aqiCode": "99149"
    },
    "tempchart": [
      {
        "time": "2023/06/27",
        "max_temp": 38.2,
        "min_temp": 20.5,
        "day_img": "9999",
        "day_text": "9999",
        "night_img": "9999",
        "night_text": "9999"
      },
      {
        "time": "2023/06/28",
        "max_temp": 33.5,
        "min_temp": 25.9,
        "day_img": "9999",
        "day_text": "9999",
        "night_img": "9999",
        "night_text": "9999"
      },
      {
        "time": "2023/06/29",
        "max_temp": 36.2,
        "min_temp": 23.8,
        "day_img": "9999",
        "day_text": "9999",
        "night_img": "9999",
        "night_text": "9999"
      },
      {
        "time": "2023/06/30",
        "max_temp": 37.8,
        "min_temp": 22.4,
        "day_img": "9999",
        "day_text": "9999",
        "night_img": "9999",
        "night_text": "9999"
      },
      {
        "time": "2023/07/01",
        "max_temp": 36.6,
        "min_temp": 23.6,
        "day_img": "9999",
        "day_text": "9999",
        "night_img": "9999",
        "night_text": "9999"
      },
      {
        "time": "2023/07/02",
        "max_temp": 35.4,
        "min_temp": 27.6,
        "day_img": "9999",
        "day_text": "9999",
        "night_img": "9999",
        "night_text": "9999"
      },
      {
        "time": "2023/07/03",
        "max_temp": 31.8,
        "min_temp": 25.4,
        "day_img": "9999",
        "day_text": "9999",
        "night_img": "9999",
        "night_text": "9999"
      },
      {
        "time": "2023/07/04",
        "max_temp": 35.5,
        "min_temp": 22.4,
        "day_img": "1",
        "day_text": "多云",
        "night_img": "0",
        "night_text": "晴"
      },
      {
        "time": "2023/07/05",
        "max_temp": 40.3,
        "min_temp": 24.7,
        "day_img": "0",
        "day_text": "晴",
        "night_img": "0",
        "night_text": "晴"
      },
      {
        "time": "2023/07/06",
        "max_temp": 41.9,
        "min_temp": 25.3,
        "day_img": "0",
        "day_text": "晴",
        "night_img": "0",
        "night_text": "晴"
      },
      {
        "time": "2023/07/07",
        "max_temp": 40,
        "min_temp": 24.9,
        "day_img": "1",
        "day_text": "多云",
        "night_img": "0",
        "night_text": "晴"
      },
      {
        "time": "2023/07/08",
        "max_temp": 37.1,
        "min_temp": 24.6,
        "day_img": "1",
        "day_text": "多云",
        "night_img": "0",
        "night_text": "晴"
      },
      {
        "time": "2023/07/09",
        "max_temp": 37.7,
        "min_temp": 24.9,
        "day_img": "1",
        "day_text": "多云",
        "night_img": "1",
        "night_text": "多云"
      },
      {
        "time": "2023/07/10",
        "max_temp": 38.8,
        "min_temp": 24.8,
        "day_img": "7",
        "day_text": "小雨",
        "night_img": "8",
        "night_text": "中雨"
      }
    ],
    "passedchart": [
      {
        "rain1h": 0,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 26.1,
        "tempDiff": "",
        "humidity": 82,
        "pressure": 993,
        "windDirection": 347,
        "windSpeed": 2.4,
        "time": "2023-07-04 09:00"
      },
      {
        "rain1h": 0,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 24.6,
        "tempDiff": "",
        "humidity": 91,
        "pressure": 993,
        "windDirection": 339,
        "windSpeed": 2.7,
        "time": "2023-07-04 08:00"
      },
      {
        "rain1h": 0.3,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 23.7,
        "tempDiff": "",
        "humidity": 95,
        "pressure": 992,
        "windDirection": 29,
        "windSpeed": 3.5,
        "time": "2023-07-04 07:00"
      },
      {
        "rain1h": 0.3,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 23.5,
        "tempDiff": "",
        "humidity": 95,
        "pressure": 992,
        "windDirection": 17,
        "windSpeed": 1,
        "time": "2023-07-04 06:00"
      },
      {
        "rain1h": 0.1,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 23.4,
        "tempDiff": "",
        "humidity": 96,
        "pressure": 992,
        "windDirection": 24,
        "windSpeed": 2.5,
        "time": "2023-07-04 05:00"
      },
      {
        "rain1h": 0.8,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 23.6,
        "tempDiff": "",
        "humidity": 96,
        "pressure": 991,
        "windDirection": 7,
        "windSpeed": 2.9,
        "time": "2023-07-04 04:00"
      },
      {
        "rain1h": 3.2,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 23.8,
        "tempDiff": "",
        "humidity": 97,
        "pressure": 992,
        "windDirection": 333,
        "windSpeed": 2.3,
        "time": "2023-07-04 03:00"
      },
      {
        "rain1h": 0.9,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 24.2,
        "tempDiff": "",
        "humidity": 96,
        "pressure": 991,
        "windDirection": 330,
        "windSpeed": 5.7,
        "time": "2023-07-04 02:00"
      },
      {
        "rain1h": 0.7,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 24.8,
        "tempDiff": "",
        "humidity": 96,
        "pressure": 992,
        "windDirection": 326,
        "windSpeed": 2.2,
        "time": "2023-07-04 01:00"
      },
      {
        "rain1h": 1,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 25.2,
        "tempDiff": "",
        "humidity": 97,
        "pressure": 992,
        "windDirection": 324,
        "windSpeed": 3.5,
        "time": "2023-07-04 00:00"
      },
      {
        "rain1h": 3.7,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 25.4,
        "tempDiff": "",
        "humidity": 96,
        "pressure": 993,
        "windDirection": 335,
        "windSpeed": 5.1,
        "time": "2023-07-03 23:00"
      },
      {
        "rain1h": 6.5,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 26.2,
        "tempDiff": "",
        "humidity": 97,
        "pressure": 993,
        "windDirection": 318,
        "windSpeed": 2.8,
        "time": "2023-07-03 22:00"
      },
      {
        "rain1h": 1.6,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 26.2,
        "tempDiff": "",
        "humidity": 96,
        "pressure": 993,
        "windDirection": 354,
        "windSpeed": 2.8,
        "time": "2023-07-03 21:00"
      },
      {
        "rain1h": 0.2,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 26.2,
        "tempDiff": "",
        "humidity": 95,
        "pressure": 993,
        "windDirection": 25,
        "windSpeed": 1.3,
        "time": "2023-07-03 20:00"
      },
      {
        "rain1h": 0.9,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 26.3,
        "tempDiff": "",
        "humidity": 95,
        "pressure": 993,
        "windDirection": 330,
        "windSpeed": 0.9,
        "time": "2023-07-03 19:00"
      },
      {
        "rain1h": 2.4,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 26.6,
        "tempDiff": "",
        "humidity": 92,
        "pressure": 993,
        "windDirection": 301,
        "windSpeed": 1.2,
        "time": "2023-07-03 18:00"
      },
      {
        "rain1h": 0,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 27.2,
        "tempDiff": "",
        "humidity": 86,
        "pressure": 993,
        "windDirection": 308,
        "windSpeed": 0.5,
        "time": "2023-07-03 17:00"
      },
      {
        "rain1h": 0.1,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 27.5,
        "tempDiff": "",
        "humidity": 83,
        "pressure": 994,
        "windDirection": 177,
        "windSpeed": 1.2,
        "time": "2023-07-03 16:00"
      },
      {
        "rain1h": 0,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 28.2,
        "tempDiff": "",
        "humidity": 76,
        "pressure": 994,
        "windDirection": 149,
        "windSpeed": 3,
        "time": "2023-07-03 15:00"
      },
      {
        "rain1h": 0,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 28.6,
        "tempDiff": "",
        "humidity": 77,
        "pressure": 995,
        "windDirection": 200,
        "windSpeed": 3.2,
        "time": "2023-07-03 14:00"
      },
      {
        "rain1h": 0,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 30.8,
        "tempDiff": "",
        "humidity": 70,
        "pressure": 995,
        "windDirection": 143,
        "windSpeed": 4.1,
        "time": "2023-07-03 13:00"
      },
      {
        "rain1h": 0,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 31.8,
        "tempDiff": "",
        "humidity": 62,
        "pressure": 996,
        "windDirection": 140,
        "windSpeed": 1.8,
        "time": "2023-07-03 12:00"
      },
      {
        "rain1h": 0,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 31.8,
        "tempDiff": "",
        "humidity": 62,
        "pressure": 996,
        "windDirection": 168,
        "windSpeed": 1.8,
        "time": "2023-07-03 11:00"
      },
      {
        "rain1h": 0,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 30.5,
        "tempDiff": "",
        "humidity": 71,
        "pressure": 997,
        "windDirection": 124,
        "windSpeed": 1,
        "time": "2023-07-03 10:00"
      }
    ],
    "climate": {
      "time": "1981年-2010年",
      "month": [
        {
          "month": 1,
          "maxTemp": 4,
          "minTemp": -7,
          "precipitation": 3.4
        },
        {
          "month": 2,
          "maxTemp": 8.1,
          "minTemp": -3.6,
          "precipitation": 5.8
        },
        {
          "month": 3,
          "maxTemp": 14.1,
          "minTemp": 1.9,
          "precipitation": 14.1
        },
        {
          "month": 4,
          "maxTemp": 21.6,
          "minTemp": 8.6,
          "precipitation": 20.4
        },
        {
          "month": 5,
          "maxTemp": 27,
          "minTemp": 14.1,
          "precipitation": 41.7
        },
        {
          "month": 6,
          "maxTemp": 32.3,
          "minTemp": 19.5,
          "precipitation": 64.3
        },
        {
          "month": 7,
          "maxTemp": 31.8,
          "minTemp": 22.3,
          "precipitation": 147.2
        },
        {
          "month": 8,
          "maxTemp": 30.2,
          "minTemp": 20.9,
          "precipitation": 104.3
        },
        {
          "month": 9,
          "maxTemp": 26.8,
          "minTemp": 15.3,
          "precipitation": 48.1
        },
        {
          "month": 10,
          "maxTemp": 21.4,
          "minTemp": 8.7,
          "precipitation": 32.8
        },
        {
          "month": 11,
          "maxTemp": 12.7,
          "minTemp": 0.7,
          "precipitation": 12.3
        },
        {
          "month": 12,
          "maxTemp": 5.8,
          "minTemp": -4.8,
          "precipitation": 3.8
        }
      ]
    },
    "radar": {
      "title": "华北",
      "image": "/product/2023/07/04/RDCP/SEVP_AOC_RDCP_SLDAS3_ECREF_ANCN_L88_PI_20230704014200000.PNG?v=1688435669804",
      "url": "/publish/radar/huabei.html"
    }
  }
  }
  console.log({
    "real": {
      "station": {
        "code": "53996",
        "province": "河北省",
        "city": "成安",
        "url": "/publish/forecast/AHE/chengan.html"
      },
      "publish_time": "2023-07-04 09:35",
      "weather": {
        "temperature": 27.2,
        "temperatureDiff": -2.5,
        "airpressure": 9999,
        "humidity": 76,
        "rain": 0,
        "rcomfort": 73,
        "icomfort": 1,
        "info": "多云",
        "img": "1",
        "feelst": 26.2
      },
      "wind": {
        "direct": "西北风",
        "degree": 343,
        "power": "微风",
        "speed": 1.9
      },
      "warn": {
            "alert": "2023年07月03日08时河南省气象台发布暴雨黄色预警信号",
            "pic": "http://image.nmc.cn/assets/img/alarm/p0002003.png",
            "province": "河南省",
            "city": "9999",
            "url": "/publish/alarm/41000041600000_20230703080050.html",
            "issuecontent": "河南省气象台2023年7月3日8时升级发布暴雨黄色预警：7月2日8时至3日7时，我省黄淮之间66个县（市、区）出现暴雨，其中大于100毫米的有26个县（市、区）。预计7月3日8时至4日8时，南阳北部和东部、平顶山南部、漯河东部、驻马店、信阳西北部、周口、商丘东部部分县市将出现暴雨（50～80毫米），其中南阳北部和东部、平顶山西南部、周口东部个别站点雨量可达100毫米以上，并伴有雷电、短时强降水（50～80毫米/小时）、雷暴大风等强对流天气。请注意防范。上述地区的相邻县市（区）也需关注。\n \n防御指南：\n1. 政府及相关部门按照职责做好防暴雨准备工作,密切关注属地气象部门发布的最新预报信息和气象灾害预警信号; \n2. 学校、幼儿园可提前或推迟上下学时间，确保学生、幼儿安全。\n3. 驾车出行要确保刹车、转向、雨刷等部件安全有效，遇到积水较深的路段，不要贸然涉水通过，车辆在涉水行驶中熄火，应在水位上涨前快速撤离，不要在车内等待救援。\n4.行人要远离建筑工地临时围墙及建在山坡上的围墙等，警惕井盖、下水道、排污井，避开垂落的电线，不要触摸路灯灯杆或信号灯灯杆，避免漏电，不要在树下避雨。\n5.检查城市、农田、鱼塘排水系统，做好排涝措施。",
            "fmeans": "1.政府及相关部门按照职责做好防暴雨工作；2.交通管理部门应当根据路况在强降雨路段采取交通管制措施，在积水路段实行交通引导；3.切断低洼地带有危险的室外电源，暂停在空旷地方的户外作业，转移危险地带人员和危房居民到安全场所避雨；4.检查城市、农田、鱼塘排水系统，采取必要的排涝措施。",
            "signaltype": "暴雨",
            "signallevel": "黄色",
            "pic2": "p0002003.png"
      }
    },
    "predict": {
      "station": {
        "code": "53996",
        "province": "河北省",
        "city": "成安",
        "url": "/publish/forecast/AHE/chengan.html"
      },
      "publish_time": "2023-07-04 08:00",
      "detail": [
        {
          "date": "2023-07-04",
          "pt": "2023-07-04 08:00",
          "day": {
            "weather": {
              "info": "多云",
              "img": "1",
              "temperature": "35"
            },
            "wind": {
              "direct": "北风",
              "power": "微风"
            }
          },
          "night": {
            "weather": {
              "info": "晴",
              "img": "0",
              "temperature": "22"
            },
            "wind": {
              "direct": "西南风",
              "power": "微风"
            }
          }
        },
        {
          "date": "2023-07-05",
          "pt": "2023-07-04 08:00",
          "day": {
            "weather": {
              "info": "晴",
              "img": "0",
              "temperature": "40"
            },
            "wind": {
              "direct": "西风",
              "power": "微风"
            }
          },
          "night": {
            "weather": {
              "info": "晴",
              "img": "0",
              "temperature": "24"
            },
            "wind": {
              "direct": "西南风",
              "power": "微风"
            }
          }
        },
        {
          "date": "2023-07-06",
          "pt": "2023-07-04 08:00",
          "day": {
            "weather": {
              "info": "晴",
              "img": "0",
              "temperature": "41"
            },
            "wind": {
              "direct": "南风",
              "power": "微风"
            }
          },
          "night": {
            "weather": {
              "info": "晴",
              "img": "0",
              "temperature": "25"
            },
            "wind": {
              "direct": "南风",
              "power": "微风"
            }
          }
        },
        {
          "date": "2023-07-07",
          "pt": "2023-07-04 08:00",
          "day": {
            "weather": {
              "info": "多云",
              "img": "1",
              "temperature": "40"
            },
            "wind": {
              "direct": "东北风",
              "power": "微风"
            }
          },
          "night": {
            "weather": {
              "info": "晴",
              "img": "0",
              "temperature": "24"
            },
            "wind": {
              "direct": "西北风",
              "power": "微风"
            }
          }
        },
        {
          "date": "2023-07-08",
          "pt": "2023-07-04 08:00",
          "day": {
            "weather": {
              "info": "多云",
              "img": "1",
              "temperature": "37"
            },
            "wind": {
              "direct": "东风",
              "power": "微风"
            }
          },
          "night": {
            "weather": {
              "info": "晴",
              "img": "0",
              "temperature": "24"
            },
            "wind": {
              "direct": "东风",
              "power": "微风"
            }
          }
        },
        {
          "date": "2023-07-09",
          "pt": "2023-07-04 08:00",
          "day": {
            "weather": {
              "info": "多云",
              "img": "1",
              "temperature": "37"
            },
            "wind": {
              "direct": "东风",
              "power": "微风"
            }
          },
          "night": {
            "weather": {
              "info": "多云",
              "img": "1",
              "temperature": "24"
            },
            "wind": {
              "direct": "东风",
              "power": "微风"
            }
          }
        },
        {
          "date": "2023-07-10",
          "pt": "2023-07-04 08:00",
          "day": {
            "weather": {
              "info": "小雨",
              "img": "7",
              "temperature": "38"
            },
            "wind": {
              "direct": "东南风",
              "power": "微风"
            }
          },
          "night": {
            "weather": {
              "info": "中雨",
              "img": "8",
              "temperature": "24"
            },
            "wind": {
              "direct": "东南风",
              "power": "微风"
            }
          }
        }
      ]
    },
    "air": {
      "forecasttime": "2023-07-04 09:00",
      "aqi": 30,
      "aq": 1,
      "text": "优",
      "aqiCode": "99149"
    },
    "tempchart": [
      {
        "time": "2023/06/27",
        "max_temp": 38.2,
        "min_temp": 20.5,
        "day_img": "9999",
        "day_text": "9999",
        "night_img": "9999",
        "night_text": "9999"
      },
      {
        "time": "2023/06/28",
        "max_temp": 33.5,
        "min_temp": 25.9,
        "day_img": "9999",
        "day_text": "9999",
        "night_img": "9999",
        "night_text": "9999"
      },
      {
        "time": "2023/06/29",
        "max_temp": 36.2,
        "min_temp": 23.8,
        "day_img": "9999",
        "day_text": "9999",
        "night_img": "9999",
        "night_text": "9999"
      },
      {
        "time": "2023/06/30",
        "max_temp": 37.8,
        "min_temp": 22.4,
        "day_img": "9999",
        "day_text": "9999",
        "night_img": "9999",
        "night_text": "9999"
      },
      {
        "time": "2023/07/01",
        "max_temp": 36.6,
        "min_temp": 23.6,
        "day_img": "9999",
        "day_text": "9999",
        "night_img": "9999",
        "night_text": "9999"
      },
      {
        "time": "2023/07/02",
        "max_temp": 35.4,
        "min_temp": 27.6,
        "day_img": "9999",
        "day_text": "9999",
        "night_img": "9999",
        "night_text": "9999"
      },
      {
        "time": "2023/07/03",
        "max_temp": 31.8,
        "min_temp": 25.4,
        "day_img": "9999",
        "day_text": "9999",
        "night_img": "9999",
        "night_text": "9999"
      },
      {
        "time": "2023/07/04",
        "max_temp": 35.5,
        "min_temp": 22.4,
        "day_img": "1",
        "day_text": "多云",
        "night_img": "0",
        "night_text": "晴"
      },
      {
        "time": "2023/07/05",
        "max_temp": 40.3,
        "min_temp": 24.7,
        "day_img": "0",
        "day_text": "晴",
        "night_img": "0",
        "night_text": "晴"
      },
      {
        "time": "2023/07/06",
        "max_temp": 41.9,
        "min_temp": 25.3,
        "day_img": "0",
        "day_text": "晴",
        "night_img": "0",
        "night_text": "晴"
      },
      {
        "time": "2023/07/07",
        "max_temp": 40,
        "min_temp": 24.9,
        "day_img": "1",
        "day_text": "多云",
        "night_img": "0",
        "night_text": "晴"
      },
      {
        "time": "2023/07/08",
        "max_temp": 37.1,
        "min_temp": 24.6,
        "day_img": "1",
        "day_text": "多云",
        "night_img": "0",
        "night_text": "晴"
      },
      {
        "time": "2023/07/09",
        "max_temp": 37.7,
        "min_temp": 24.9,
        "day_img": "1",
        "day_text": "多云",
        "night_img": "1",
        "night_text": "多云"
      },
      {
        "time": "2023/07/10",
        "max_temp": 38.8,
        "min_temp": 24.8,
        "day_img": "7",
        "day_text": "小雨",
        "night_img": "8",
        "night_text": "中雨"
      }
    ],
    "passedchart": [
      {
        "rain1h": 0,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 26.1,
        "tempDiff": "",
        "humidity": 82,
        "pressure": 993,
        "windDirection": 347,
        "windSpeed": 2.4,
        "time": "2023-07-04 09:00"
      },
      {
        "rain1h": 0,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 24.6,
        "tempDiff": "",
        "humidity": 91,
        "pressure": 993,
        "windDirection": 339,
        "windSpeed": 2.7,
        "time": "2023-07-04 08:00"
      },
      {
        "rain1h": 0.3,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 23.7,
        "tempDiff": "",
        "humidity": 95,
        "pressure": 992,
        "windDirection": 29,
        "windSpeed": 3.5,
        "time": "2023-07-04 07:00"
      },
      {
        "rain1h": 0.3,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 23.5,
        "tempDiff": "",
        "humidity": 95,
        "pressure": 992,
        "windDirection": 17,
        "windSpeed": 1,
        "time": "2023-07-04 06:00"
      },
      {
        "rain1h": 0.1,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 23.4,
        "tempDiff": "",
        "humidity": 96,
        "pressure": 992,
        "windDirection": 24,
        "windSpeed": 2.5,
        "time": "2023-07-04 05:00"
      },
      {
        "rain1h": 0.8,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 23.6,
        "tempDiff": "",
        "humidity": 96,
        "pressure": 991,
        "windDirection": 7,
        "windSpeed": 2.9,
        "time": "2023-07-04 04:00"
      },
      {
        "rain1h": 3.2,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 23.8,
        "tempDiff": "",
        "humidity": 97,
        "pressure": 992,
        "windDirection": 333,
        "windSpeed": 2.3,
        "time": "2023-07-04 03:00"
      },
      {
        "rain1h": 0.9,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 24.2,
        "tempDiff": "",
        "humidity": 96,
        "pressure": 991,
        "windDirection": 330,
        "windSpeed": 5.7,
        "time": "2023-07-04 02:00"
      },
      {
        "rain1h": 0.7,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 24.8,
        "tempDiff": "",
        "humidity": 96,
        "pressure": 992,
        "windDirection": 326,
        "windSpeed": 2.2,
        "time": "2023-07-04 01:00"
      },
      {
        "rain1h": 1,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 25.2,
        "tempDiff": "",
        "humidity": 97,
        "pressure": 992,
        "windDirection": 324,
        "windSpeed": 3.5,
        "time": "2023-07-04 00:00"
      },
      {
        "rain1h": 3.7,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 25.4,
        "tempDiff": "",
        "humidity": 96,
        "pressure": 993,
        "windDirection": 335,
        "windSpeed": 5.1,
        "time": "2023-07-03 23:00"
      },
      {
        "rain1h": 6.5,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 26.2,
        "tempDiff": "",
        "humidity": 97,
        "pressure": 993,
        "windDirection": 318,
        "windSpeed": 2.8,
        "time": "2023-07-03 22:00"
      },
      {
        "rain1h": 1.6,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 26.2,
        "tempDiff": "",
        "humidity": 96,
        "pressure": 993,
        "windDirection": 354,
        "windSpeed": 2.8,
        "time": "2023-07-03 21:00"
      },
      {
        "rain1h": 0.2,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 26.2,
        "tempDiff": "",
        "humidity": 95,
        "pressure": 993,
        "windDirection": 25,
        "windSpeed": 1.3,
        "time": "2023-07-03 20:00"
      },
      {
        "rain1h": 0.9,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 26.3,
        "tempDiff": "",
        "humidity": 95,
        "pressure": 993,
        "windDirection": 330,
        "windSpeed": 0.9,
        "time": "2023-07-03 19:00"
      },
      {
        "rain1h": 2.4,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 26.6,
        "tempDiff": "",
        "humidity": 92,
        "pressure": 993,
        "windDirection": 301,
        "windSpeed": 1.2,
        "time": "2023-07-03 18:00"
      },
      {
        "rain1h": 0,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 27.2,
        "tempDiff": "",
        "humidity": 86,
        "pressure": 993,
        "windDirection": 308,
        "windSpeed": 0.5,
        "time": "2023-07-03 17:00"
      },
      {
        "rain1h": 0.1,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 27.5,
        "tempDiff": "",
        "humidity": 83,
        "pressure": 994,
        "windDirection": 177,
        "windSpeed": 1.2,
        "time": "2023-07-03 16:00"
      },
      {
        "rain1h": 0,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 28.2,
        "tempDiff": "",
        "humidity": 76,
        "pressure": 994,
        "windDirection": 149,
        "windSpeed": 3,
        "time": "2023-07-03 15:00"
      },
      {
        "rain1h": 0,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 28.6,
        "tempDiff": "",
        "humidity": 77,
        "pressure": 995,
        "windDirection": 200,
        "windSpeed": 3.2,
        "time": "2023-07-03 14:00"
      },
      {
        "rain1h": 0,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 30.8,
        "tempDiff": "",
        "humidity": 70,
        "pressure": 995,
        "windDirection": 143,
        "windSpeed": 4.1,
        "time": "2023-07-03 13:00"
      },
      {
        "rain1h": 0,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 31.8,
        "tempDiff": "",
        "humidity": 62,
        "pressure": 996,
        "windDirection": 140,
        "windSpeed": 1.8,
        "time": "2023-07-03 12:00"
      },
      {
        "rain1h": 0,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 31.8,
        "tempDiff": "",
        "humidity": 62,
        "pressure": 996,
        "windDirection": 168,
        "windSpeed": 1.8,
        "time": "2023-07-03 11:00"
      },
      {
        "rain1h": 0,
        "rain24h": 9999,
        "rain12h": 9999,
        "rain6h": 9999,
        "temperature": 30.5,
        "tempDiff": "",
        "humidity": 71,
        "pressure": 997,
        "windDirection": 124,
        "windSpeed": 1,
        "time": "2023-07-03 10:00"
      }
    ],
    "climate": {
      "time": "1981年-2010年",
      "month": [
        {
          "month": 1,
          "maxTemp": 4,
          "minTemp": -7,
          "precipitation": 3.4
        },
        {
          "month": 2,
          "maxTemp": 8.1,
          "minTemp": -3.6,
          "precipitation": 5.8
        },
        {
          "month": 3,
          "maxTemp": 14.1,
          "minTemp": 1.9,
          "precipitation": 14.1
        },
        {
          "month": 4,
          "maxTemp": 21.6,
          "minTemp": 8.6,
          "precipitation": 20.4
        },
        {
          "month": 5,
          "maxTemp": 27,
          "minTemp": 14.1,
          "precipitation": 41.7
        },
        {
          "month": 6,
          "maxTemp": 32.3,
          "minTemp": 19.5,
          "precipitation": 64.3
        },
        {
          "month": 7,
          "maxTemp": 31.8,
          "minTemp": 22.3,
          "precipitation": 147.2
        },
        {
          "month": 8,
          "maxTemp": 30.2,
          "minTemp": 20.9,
          "precipitation": 104.3
        },
        {
          "month": 9,
          "maxTemp": 26.8,
          "minTemp": 15.3,
          "precipitation": 48.1
        },
        {
          "month": 10,
          "maxTemp": 21.4,
          "minTemp": 8.7,
          "precipitation": 32.8
        },
        {
          "month": 11,
          "maxTemp": 12.7,
          "minTemp": 0.7,
          "precipitation": 12.3
        },
        {
          "month": 12,
          "maxTemp": 5.8,
          "minTemp": -4.8,
          "precipitation": 3.8
        }
      ]
    },
    "radar": {
      "title": "华北",
      "image": "/product/2023/07/04/RDCP/SEVP_AOC_RDCP_SLDAS3_ECREF_ANCN_L88_PI_20230704014200000.PNG?v=1688435669804",
      "url": "/publish/radar/huabei.html"
    }
  })
  return data
}
export function tqybweather(params) {
    return request({
      url: "http://www.nmc.cn/rest/weather",
      method: "GET",
      params
    });
}