<template>
  <!-- <div class="content_box theme_bg"> -->
  <div>
    <div class="whole">
      <!-- <div class="Location">
        <p class="title_name">齐齐哈尔气象</p>
        <van-tabs color="#CAE3E0" class="prediction_tab" v-model="activeYbConfig">
          <van-tab v-for="item of predictionList" :key="item" :title="item"></van-tab>
        </van-tabs>
      </div> -->
      <!-- <div style="margin: 50px;">&nbsp;</div> -->
      <!--日出日落、温度-->
      <div class="weaPart1">
        <p class="region-name">
          {{ regionName }}
          <span>{{ lastUpdate }}</span></p>
        <div class="weatherDiv1">
          <p class="font40">
            <span class="wd">{{ tqskData.WeatherSk.Temperature }}</span>
            <span class="wd">
              <span class="wddw">℃</span>
              <span class="weaState">{{ tqweaState }}</span>
            </span>
          </p>
          <ul class="yaosu4_box">
            <li>
              <div>
                <img src="../../assets/newImg/sk_2.png" alt="">
                <span>湿度</span>
              </div>
              <p>{{ tqskData.WeatherSk.Humidity }}%</p>
            </li>
            <li>
              <div>
                <img src="../../assets/newImg/sk_4.png" alt="">
                <span>{{ hourtqyb_tqsk.fx }}</span>
              </div>
              <p>
                {{ gerWindLevel(hourtqyb_tqsk.fs) }}
              </p>
            </li>
            <li>
              <div>
                <img src="../../assets/newImg/sk_3.png" alt="">
                <span>降雨</span>
              </div>
              <p>{{ tqskData.WeatherSk.Rain }}mm</p>
            </li>
            <li>
              <div>
                <img src="../../assets/newImg/sk_5.png" alt="">
                <span>气压</span>
              </div>
              <p> {{ tqskData.WeatherSk.Pressure }}hPa</p>
            </li>
          </ul>
          <div class="warning_box">
              <div class="warning warning_info" v-if="warnInfo" >
                <van-swipe class="my-swipe" @change="swipeonChange" :autoplay="3000" indicator-color="white">
                  <van-swipe-item v-for="(item,index) of warnInfo" :key="index">
                    <img :src="item.imgUrl" alt="" srcset="" >
                  </van-swipe-item>
                  <template #indicator>
                    <div class="custom-indicator">{{ customIndicator + 1 }}/{{warnInfo.length}}</div>
                  </template>
                </van-swipe>
                <!-- <img :src="warnInfo.pic" alt="" srcset="" > -->
                <!-- <img v-for="item of warnInfo" :src="warnInfo.pic" alt="" srcset="" > -->
              </div>
              <div class="warning empty_warning" v-else>
                <svg t="1688455006359" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4081" width="16" height="16"><path d="M827.2 429.76c0-149.44-113.92-272.64-259.52-287.04a64.512 64.512 0 1 0-126.4-0.96c-13.44 0.64-26.56 2.56-39.04 5.12l425.28 425.28v-142.4zM966.08 869.12l-138.88-138.88v-2.88L294.4 194.56c-0.64 0.32-1.28 0.64-1.6 1.28L144.32 47.36a46.72 46.72 0 0 0-66.56 0c-18.24 18.24-18.24 48 0 66.56l150.08 150.08c-29.12 43.52-46.4 96-46.4 152.32v337.6l-35.2 30.72c-11.52 10.24-4.48 29.12 10.88 29.12h620.8l121.92 121.92c9.28 9.28 21.12 13.76 33.28 13.76s24-4.48 33.28-13.76c17.92-18.24 17.92-48-0.32-66.56zM504.32 1014.08c64.96 0 117.44-52.48 117.44-117.44h-234.88c0 64.64 52.48 117.44 117.44 117.44z" fill="#ffffff" p-id="4082"></path></svg>
                 &nbsp;暂无预警
              </div>
          </div>
        </div>
      </div>
      <div style="margin: 150px;">&nbsp;</div>

      <!--两天预报-->
      <div class="black tianYb2">
        <ul v-if="tomorrowSky">
          <li v-for="item of tomorrowSky" :key="item.name">
            <div class="day_tianqi">
              <div>{{ item.minTem }}&nbsp;/&nbsp;{{ item.maxTem }}°C</div>
              <span>{{ item.name }}</span>
            </div>
            <div class="day_yaosu">
              <img :src="item.imgSrc" alt="" />
              <span>{{ item.tqsk }}</span>
            </div>
          </li>
        </ul>
      </div>
      <div class="beijing">
        <div class="Tips_box">
          <div class="xiaolb">
            <img src="../../assets/newImg/喇叭.png" alt="">
          </div>
          <p class="remind">{{ tpybTips.observe[0] }}</p>
        </div>
        <!--逐3小时预报-->
        <div class="black hour">
          <p class="title xiaoshiYB">
            <span @click="xiaoshiYB(1)" :class="{ 'xzXiaoshiYb': hourYB === 1 }">逐1小时预报</span>
            <span @click="xiaoshiYB(3)" :class="{ 'xzXiaoshiYb': hourYB === 3 }">逐3小时预报</span>
          </p>
          <div class="HourDragDrop">
            <div :style="`width:${hourYB == 3 ? 96 * 1 : 96 * 3}%`" class="toTop_box">
              <div ref="wdecharts" id="wd"></div>
              <ul class="toTop">
                <li :style="`width:${hourYB == 3 ? 12.5 / 1 : 12.5 / 3}%`" v-for="(item, index) of hourYbList"
                  :key="index">
                  <img :src="item.img" alt="" class="weather">
                  <div>
                    <!-- <img :style="`transform: rotate(${item.fx}deg)`" src="@/assets/images/yb_1.png" alt=""> -->
                    <div>{{ item.fx }}</div>
                    <span>{{ item.fs }}</span>
                  </div>
                  <!-- <div>
                      <span :style="`background:${item.kqzlColor}`" class="air"></span>
                      <span>{{ item.kqzl }}</span>
                    </div> -->
                  <p>{{ item.time }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!--六天预报-->
        <div class="black prediction">
          <p class="title">七天预报</p>
          <div ref="yb1Echarts" id="yb1"></div>
          <div ref="yb2Echarts" id="yb2"></div>
          <ul class="weekyb">
            <li v-for="item of SevenDaysList" :key="item.time">
              <p>{{ item.week }}</p>
              <p class="date">{{ item.time }}</p>
              <img :src="item.img1" alt="">
              <img :src="item.img2" alt="" class="night">
              <p>{{ item.fx }}<br>{{ item.fs }}</p>
              <!-- <div>
                <p>{{ item.kqzl }}</p>
                <span :style="`background:${item.kqzlColor}`" class="color"></span>
              </div> -->
            </li>
          </ul>
        </div>
        <div class="black rcril_box">
          <p class="title">日出日落</p>
          <div id="weather" ref="ecWeatherDiv" class="richuriluo">
            <canvas ref="tqskCanvasdewr" class="tqskCanvasdewr" id='c1' :width="AQIPartSize.width"
              :height="AQIPartSize.height">
              <span>该浏览器不支持canvas</span>
            </canvas>
            <div class="left module">
              <!-- <img src="@/assets/images/yb_2.png"  alt="" /> -->
              <p class="SunRise">{{ SunTrajectory.SunRise }}</p>
            </div>
            <div class="right module">
              <!-- <img src="@/assets/images/yb_3.png"  alt="" /> -->
              <p class="SunSet">{{ SunTrajectory.SunSet }}</p>
            </div>
            <div class="pprTips">{{ SunTrajectory.countdown }}</div>
          </div>
        </div>
      </div>
    </div>
    <div style="display:none;" id="container"></div>
  </div>
</template>

<script>
import { computeSunRiseSunSet, getWeeknum1 } from "@/utils/tool.js";
import { Getfengxiang, Getfengsu } from "@/utils/Getfengxiang.js";
import { Format, Formats, noToChinese } from "@/utils/common.js";
import { createCanvas, createWDLine } from "./TianQiYuBao.js";
import { GetTianQi, GetLevel } from "@/utils/Gettianqi.js";
import Calendar from '@/utils/calendar.js';
import { tqybdata, tqybdatas, tqybweather, GetYuJing } from "./Api.js";
import { GetTicks } from "@/api/index.js";
import china_code from "@/components/my-city-picker/china_code.json"
var createWDrateChart = null;
export default {
  props: {
    position: {},
  },
  components: {
    // myPicker
  },
  name: "TianQiYuBao",
  data() {
    return {
      tdMap: null,
      wzTxt: ['一', '两', '三', '四', '五', '六', '七', '八', '九', '十', '十一', '十二', '十三', '十四', '十五'],
      lastUpdate: '1分钟前更新',
      customIndicator:0,
      tomorrowSky: null,
      gethourYbFun: null,
      CityInfoData:null,
      hourYB: 3,
      regionName: '',
      ybConfig: false, // 预报设置弹窗
      warnInfo:null,
      AQIPartSize: {},//
      tpybTips: { observe: {} },
      tqskData: { WeatherSk: {} }, // 天气实况数据
      tqImg: null, // 天气实况图片
      hourtqyb_aqi_value: '', // 空气质量
      hourtqyb_tqsk: {},// 风速风向
      SunTrajectory: {}, // 日出日落
      hourYbList: [], // 1小时预报
      SevenDaysList: [],// 7天预报
      tqweaState: '',
      activeYbConfig: '天气预报',
      china_code:china_code,
      predictionList: [
        '天气预报',
        '乡镇预报',
        '全省预报',
        '台风路径',
        '雷电监测',
        '雾与霾预报',
      ],
      UserLocation: { lng: mapCenter[0], lat: mapCenter[1] } // 邯郸市
      // UserLocation: { lng: 113.713054, lat: 34.75605} // 郑州
    }
  },

  created() {
    // 缓存数据；
    // var objData = localStorage.getItem('TQDATA');
    // if (objData) {
    //   var data = JSON.parse(objData);
    //   var last = this.handlerDateDurationCurrent(data.Update);
    //   this.lastUpdate = last
    //   // var last = this.handlerDateDurationCurrent('2022/07/15 00:20:00');
    //   this.tqskData = data.data;
    //   setTimeout(() => {
    //     this.tqCanvas();
    //   }, 100)
    // }
    // 判断是否是从全省预报跳转过来的
    if (this.position && (this.position.lat && this.position.lng)) {
      this.UserLocation = this.position
      this.getDataFun();
      return
    }
    /** wx方法只有在生产环境中使用  所以开发环境直接调用写死的经纬度获取数据*/
    // if (process.env.NODE_ENV === "development") {
    //   this.getDataFun(); // 开发环境
    // } else {
    //   this.wxRegister(); // 生产环境
    // }
    this.setTimeText();
    // this.getColorLevel(24)
  },

  mounted() {
    this.getCanvasSize();
    // setTimeout(() => {
    //   this.mapCreat();
    // }, 1000);
  },

  methods: {
    getColorLevel: function (val) {
      var obj = GetLevel(val);
      return obj
    },

    gerWindLevel: function (val) {
      var str = Getfengsu(val).name;
      return str
    },

    // 获取 用户当前位置
    // async wxRegister() {
    //   let url = window.location.href;
    //   // var res = await GetTicks({ url: Wx_Url });
    //   var res = await GetTicks({ url: url });
    //   var thas = this;
    //   const { appId, jsapi_ticket, nonceStr, signature, timestamp } = res;
    //   // console.log({appId, jsapi_ticket, nonceStr, signature, timestamp});
    //   wx.config({
    //     debug: isdebug, // 开启调试模式
    //     appId: appId, // 必填，公众号的唯一标识
    //     timestamp: timestamp, // 必填，生成签名的时间戳
    //     nonceStr: nonceStr, // 必填，生成签名的随机串
    //     signature: signature, // 必填，签名，见附录1
    //     jsApiList: ["getLocation"]
    //   });
    //   wx.error(function (res) {
    //     console.log(res);
    //   });
    //   wx.ready(function () {
    //     wx.getLocation({
    //       type: "gcj02",
    //       success: function (res) {
    //         var latitude = res.latitude;
    //         var longitude = res.longitude;
    //         thas.UserLocation = { lng: longitude, lat: latitude }
    //         thas.getDataFun()
    //       },
    //       cancel: function (res) {
    //         alert("定位失败，权限不足" + res.msg);
    //         console.log("cancel定位失败，权限不足" + res.msg);
    //       },
    //       error: function (res) {
    //         alert("定位失败，权限不足" + res.msg);
    //         console.log("error定位失败，权限不足" + res.msg);
    //       },
    //       fail: function (res) {
    //         console.log("fail", res)
    //       }
    //       // fail: function (res) {
    //       //     console.info('ready getLocation fail=' + JSON.stringify(res))
    //       //     console.log('出现故障，请联系管理员')
    //       // }
    //     });
    //   });
    // },

    // 切换城市
  
    // 城市选择，初始化选择邯郸
    selCityData(e){
      console.log(e)
      this.CityInfoData = e;
      this.warnInfo = null;
      this.UserLocation = {lng:e.lng,lat:e.lat};
      this.getDataFun();
    },

    getCanvasSize() {
      var height = this.$refs.ecWeatherDiv.offsetHeight;
      var width = this.$refs.ecWeatherDiv.offsetWidth;
      this.AQIPartSize = { height, width };
    },
    swipeonChange(index){
      this.customIndicator = index;
    },

    async getweather(){
      var obj = {
        area: USERINFO_AREA.Areacode
      }
      const { data, code } = await GetYuJing(obj);
      this.$loading.hide();
      if(code != 200)return 


      /**过滤时间 */
      let glDate = (times)=>{
        let date = new Date().getTime();
        let warningTime = new Date(times).getTime(); // 预警插入时间
        let isTime =  warningTime >= date-(1000*60*60*12);
        return isTime
      }
      let list = [];

      // 如果是肥乡过滤，使用市级预警
      if(this.CityInfoData.areacode == '130428000000'){
        // this.warnInfo = this.warnInfo.filter(it=>{
        list = data.filter(it=>{
            let isShi =  it.Areacodelist.length > 12;
            let isTime = glDate(it.warningTime);
            return isTime && isShi
        })
      }else{
         list = data.filter(it=>{
          let yjlist = it.Areacodelist.split(',');
          let isCode = yjlist.includes(this.CityInfoData.areacode);
          let isTime = glDate(it.warningTime);
          return isTime && isCode
        }) 
      }
        this.warnInfo = list.map(el=>{
          let obj = {...el};
          obj.imgUrl = './image/突发预警图标Png/'+ el.eventType+'_'+el.severity+'.png';
          return obj
        }); 
      console.log(this.warnInfo)
      this.warnInfo.reverse();
      console.log(this.warnInfo)
      this.regionName = this.CityInfoData.city;
      // 获取数据后清空
      this.CityInfoData = null;

      
      // if(!this.CityInfoData){
      //   this.$loading.hide();
      //   return
      // } 
      // let code = this.CityInfoData.code;
      // // this.$loading.show();
      // let res = await tqybweather({stationid:code,_:new Date().getTime()});
      // this.$loading.hide();
      // if (res.msg != 'success') return
      // let real = res.data.real;
      // let predict = res.data.predict;
      // if(real.warn.pic != '9999'){
      //     this.warnInfo = real.warn;
      // };
      // this.regionName = this.CityInfoData.city;
      // // 获取数据后清空
      // this.CityInfoData = null;
    },

    async getDataFun() {
      this.$loading.show();
      let res = await tqybdatas(this.UserLocation);
      this.getweather();
      let { data, code, msg } = res;
      // this.$loading.hide();
      if (code != 'ok'){
        this.$loading.hide();
        alert(msg);
        return
      } 
      this.tqskData = data;
      // this.lastUpdate = '1分钟前更新'
      let objData = { data, Update: Formats(new Date()) };
      localStorage.setItem('TQDATA', JSON.stringify(objData)); //存储
      this.tqCanvas();
      
    },
    // 提交预报选择
    preSubmit() {
      // console.log(this.predictionData);
      this.closePopup();
    },

    // 选择的类型
    choiceItem(item) {
      this.predictionData.splice(this.predictionData.indexOf(item), 1);
      this.predictionData.push(item);
    },

    // 打开弹窗
    yubaoConfig() {
      this.ybConfig = true;
    },

    // 关闭弹窗
    closePopup() {
      this.ybConfig = false;
    },

    // 小时预报
    xiaoshiYB(hour) {
      // console.log(hour);
      this.hourYB = hour;
      setTimeout(() => {
        if (createWDrateChart != null && createWDrateChart != "" && createWDrateChart != undefined) {
          createWDrateChart.dispose();//销毁
        }
        this.gethourYbFun(this.tqskData.WeatherYb_1h, hour);
      }, 100)
    },
    // https://restapi.amap.com/v3/geocode/geo?parameters
    // 经纬度逆解析
    // AMapGetAddress(lnglat = [this.UserLocation.lng,this.UserLocation.lat]) {
    //   AMap.plugin("AMap.Geocoder", function() {
    //     const geocoder = new AMap.Geocoder()
    //     let that = this
    //     geocoder.getAddress(lnglat, function (status, result) {
    //       if (status === 'complete' && result.regeocode) {
    //         console.log(result.regeocode.addressComponent)
    //         // that.getStationIp(result.regeocode.addressComponent.city)
    //       } else {
    //         log.error('根据经纬度查询地址失败')
    //       }
    //     })
    //   })
    // },
    //天气状态
    tqCanvas() {
      let { WeatherYb_1h: hourtqyb, WeatherSk: tqsk, WeatherYb_24h: daytqyb, city, Tips, Rise } = this.tqskData;
      if(this.position){
        this.regionName = city;
      }
      this.tpybTips = Tips;
      var js = this.tqskData.WeatherSk.Rain + '';
      if (js.split(".")[1] && js.split(".")[1].length > 1) {
        this.tqskData.WeatherSk.Rain = Number(js).toFixed(1);
      }
      daytqyb = this.tqskData.WeatherYb_24h.filter((it, index) => index !== 0);
      this.lastUpdate = this.getCountTime(tqsk.Update_Time);
      // this.hourtqyb_aqi_value = GetLevel(hourtqyb.aqi[0].value);// 空气质量
      //获取日出日落时间
      var sunArr = Rise[0];
      this.SunTrajectory = {
        SunRise: sunArr.sunrise,// 日出
        SunSet: sunArr.sunset, // 日落
        countdown: this.countdownSun(sunArr.sunrise, sunArr.sunset) // 距离
      };
      // 风速风向
      this.hourtqyb_tqsk = {
        fx: tqsk.WindDirection, // 风向
        fs: tqsk.WindSpeed // 风速
      };

      /** 今天明天预报 */
      const tomorrow = () => {
        this.tomorrowSky = [{ name: '今日', }, { name: '明日', }];
        for (let i = 0; i < this.tomorrowSky.length; i++) {
          var it = this.tomorrowSky[i];
          var tqzk = daytqyb[i].Weather_Day;
          // it.api = GetLevel(daytqyb.aqi[i].avg);
          it.maxTem = daytqyb[i].Temperature_Max;
          it.minTem = daytqyb[i].Temperature_Min;
          it.imgSrc = require(`@/assets/最新天气图标/${tqzk}.png`);
          it.tqsk = tqzk;
          it.fx = daytqyb[i].WindDirection_Day;
          it.fs = Getfengsu((daytqyb[i].WindPower_Day).value);
        }
      };
      tomorrow();
      /**天气实况Canvas图 */
      const tqskCanvas = () => {
        var skycon = this.tqskData.WeatherSk.Weather;
        var weaState = skycon;
        var imgSrc = require(`@/assets/最新天气图标/${weaState}.png`);
        this.tqImg = imgSrc;
        this.tqweaState = weaState; // 天气状况
        var n = parseInt(Format(new Date, "HH"));
        var deg = 180 / 24 * n;
        // 180 
        var tqskCanvasdewr = this.$refs.tqskCanvasdewr;
        var size = this.AQIPartSize.width / 2 - 5;
        // console.log(size);
        setTimeout(() => {
          createCanvas(tqskCanvasdewr, deg, imgSrc, size);
        }, 50);
      };
      tqskCanvas();
      /**小时预报 */
      const xsyb = (hourtqyb, h) => {
        var aqiArr = [], // aqi
          aqicolorArr = [], // aqi对应颜色值
          tempArr = [], // 温度
          skyconArr = [], // 天气状态图标
          windSpeed = [], // 风速
          windDirection = [],// 风向
          timeArr = [];
        for (var i = 0; i < 24; i += h) {
          // var aqiVal = hourtqyb.aqi[i].value;
          // aqiArr.push(GetLevel(aqiVal).level);
          // aqicolorArr.push(GetLevel(aqiVal).color);
          var H = hourtqyb[i].Time.slice(8, 10);
          var mm = hourtqyb[i].Time.slice(10, 12);
          timeArr.push(H + ':' + mm);
          tempArr.push(parseInt(hourtqyb[i].Temperature));
          windSpeed.push(hourtqyb[i].WindPower);
          windDirection.push(hourtqyb[i].WindDirection);
          var weaState1 = hourtqyb[i].Weather;
          var imgSrc1 = require(`@/assets/最新天气图标/${weaState1}.png`);
          skyconArr.push(imgSrc1);
        }
        var lineColor = '#FFFFFF', position = 'top';
        var xdata = [];
        for (var t of timeArr) {
          xdata.push(t.slice(0, t.indexOf(":")) + "时");
        }
        createWDrateChart = createWDLine(this.$refs.wdecharts, tempArr, xdata, lineColor, position);
        this.hourYbList = timeArr.map((it, index) => {
          return {
            time: it, // 时间
            fx: windDirection[index], // 风向
            fs: windSpeed[index] + '级', // 风速
            img: skyconArr[index], // 天气实况图片
            // kqzl: aqiArr[index], // 空气质量 评级
            kqzlColor: aqicolorArr[index] // 空气质量 颜色
          }
        })
      }
      xsyb(hourtqyb, this.hourYB);
      this.gethourYbFun = xsyb;
      const SevenDaysYB = () => {
        var data3 = daytqyb;
        var aqiArr1 = [],//	aqi
          aqicolorArr1 = [],//aqi对应颜色值
          mintempArr = [],//最小温度
          maxtempArr = [],//最大温度
          skyconArr1 = [],//天气状态图标（白天）
          skyconArr2 = [],//天气状态图标（晚上）
          windSpeed1 = [],//风速
          windDirection1 = [],//风向
          weekArr = getWeeknum1(6),//周几
          dateArr = [];//日期
        // console.log(weekArr);
        // console.log(daytqyb);
        for (var i = 0; i < 7; i++) {
          // var aqiVal1 = data3.aqi[i].avg;
          // aqiArr1.push(GetLevel(aqiVal1).level)
          // aqicolorArr1.push(GetLevel(aqiVal1).color);
          mintempArr.push(parseInt(data3[i].Temperature_Min));
          maxtempArr.push(parseInt(data3[i].Temperature_Max));
          windSpeed1.push(data3[i].WindPower_Day);
          windDirection1.push(Getfengxiang(data3[i].WindDirection_Day));
          dateArr.push(data3[i].Time.split("/")[1] + "/" + data3[i].Time.split("/")[2]);
          var weaState2 = data3[i].Weather_Day;
          var weaState3 = data3[i].Weather_Night;
          var imgSrc2 = require(`@/assets/最新天气图标/${weaState2}.png`);
          var imgSrc3 = require(`@/assets/最新天气图标/${weaState3}.png`);
          skyconArr1.push(imgSrc2);
          skyconArr2.push(imgSrc3);
        }
        this.SevenDaysList = dateArr.map((it, index) => {
          return {
            time: it, // 时间
            week: weekArr[index],// 周
            fx: windDirection1[index], // 风向
            fs: this.WindLevel(windSpeed1[index]), // 风速
            img1: skyconArr1[index], // 天气实况图片
            img2: skyconArr2[index], // 天气实况图片
            // kqzl: aqiArr1[index], // 空气质量 评级
            kqzlColor: aqicolorArr1[index] // 空气质量 颜色
          }
        })
        var lineColor1 = "#F0CB73", position1 = 'top';
        createWDLine(this.$refs.yb1Echarts, maxtempArr, dateArr, lineColor1, position1);
        var lineColor2 = "#6FC5FE", position2 = 'bottom';
        createWDLine(this.$refs.yb2Echarts, mintempArr, dateArr, lineColor2, position2);
      }
      SevenDaysYB();
    },

    // 计算其他时间格式
    getCountTime(num) {
      var Y = num.slice(0, 4);
      var M = num.slice(4, 6);
      var D = num.slice(6, 8);
      var H = num.slice(8, 10);
      var mm = num.slice(10, 12);
      var ss = num.slice(12, 14) ? num.slice(12, 14) : '00';
      // var date = Y+'-'+M+'-'+D + ' ' + H + ':' + mm + ':' + ss;
      return H + ':' + mm + '更新'
    },

    // 计算日出 正午 日落 
    countdownSun(SunRise, SunSet) {
      var txt = this.wzTxt;
      var rc = SunRise.split(':')[0] * 1; // 日出
      var dq = new Date().getHours(); // 当前
      var rl = SunSet.split(':')[0] * 1; // 日落
      // console.log(rc, dq, rl);
      if (dq < rc) {
        return `距离日出还有${txt[rc - dq - 1] ? txt[rc - dq - 1] : txt[4 - dq - 1]}小时呢`
      } else if (dq < rl) {
        return `距离日落还有${txt[rl - dq - 1] ? txt[rl - dq - 1] : txt[19 - dq - 1]}小时呢`
      } else {
        return `日落${SunSet}`
      }

    },

    // 时间格式
    setTimeText() {
      const { gregorianMonth, gregorianDay, lunarMonthCn, lunarDayCn } = Calendar.data();
      var text = `${Number(gregorianMonth)}月${gregorianDay}日 农历${lunarMonthCn}${lunarDayCn}`
      return text
    },

    // 风速换算
    WindLevel(wv) {
      var level = "";
      if (wv >= 0.0 && wv <= 0.2) {
        level = "无风";
      }
      else if (wv >= 0.3 && wv <= 1.5) {
        level = "1级";
      }
      else if (wv >= 1.6 && wv <= 3.3) {
        level = "2级";
      }
      else if (wv >= 3.4 && wv <= 5.4) {
        level = "3级";
      }
      else if (wv >= 5.5 && wv <= 7.9) {
        level = "4级";
      }
      else if (wv >= 8.0 && wv <= 10.7) {
        level = "5级";
      }
      else if (wv >= 10.8 && wv <= 13.8) {
        level = "6级";
      }
      else if (wv >= 13.9 && wv <= 17.1) {
        level = "7级";
      }
      else if (wv >= 17.2 && wv <= 20.7) {
        level = "8级";
      }
      else if (wv >= 20.8 && wv <= 24.4) {
        level = "9级";
      }
      else if (wv >= 24.5 && wv <= 28.4) {
        level = "10级";
      }
      else if (wv >= 28.5 && wv <= 32.6) {
        level = "11级";
      }
      else if (wv >= 32.7 && wv <= 36.9) {
        level = "12级";
      }
      else if (wv >= 37.0 && wv <= 41.4) {
        level = "13级";
      }
      else if (wv >= 41.5 && wv <= 46.1) {
        level = "14级";
      }
      else if (wv >= 46.2 && wv <= 50.9) {
        level = "15级";
      }
      return level;
    },

    handlerDateDurationCurrent(time) {
      let d1 = new Date(time)
      let d2 = new Date()
      let cha = Math.abs(d2.getTime() - d1.getTime())
      let days = parseInt(cha / (24 * 60 * 60 * 1000))
      let hours = parseInt(cha % (24 * 60 * 60 * 1000) / (60 * 60 * 1000))
      let mins = parseInt(cha % (60 * 60 * 1000) / (60 * 1000))
      if (days) {
        var text = days > 7 ? '七前天前更新' : `${this.wzTxt[days]}天前更新`
        return text
      } else if (hours) {
        return `${hours}小时前更新`
      } else {
        return `${mins}分钟前更新`
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.my-swipe{
  width: 110px;
}
 .custom-indicator {
    position: absolute;
    right: 5px;
    bottom: 5px;
    padding: 2px 5px;
    font-size: 12px;
    background: rgba(0, 0, 0, 0.2);
    display: none;
  }
.prediction_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  li {
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 3px 15px;
    font-size: 13px;
    margin: 5px 0;
  }

  .pre--active {
    background-color: #fff;
    color: #2a4a72;
  }
}


.tqmyImg {
  // display: block;
  width: 30px;
  height: 30px;
}

.weekyb {
  overflow: auto;
  display: flex;
}

.whole {
  width: 100%;
  padding-top: 20px;
  // height: 1000px;
  overflow: auto;
  // background: url(~@/assets/images/bg_1.jpg) no-repeat top center;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7), rgb(3, 3, 3, .8));
  background-size: 100% 100%;
}

/*定位、日期*/
.Location {
  width: 100%;
  text-align: center;
  position: fixed;
  background-color: var(--bg_background_color);
  // background-color: rgba(60,154,141,.8);
  z-index: 1000;
  // position: relative;

  .title_name {
    padding: 0;
    font-size: 18px;
  }


  .yubao_config {
    position: absolute;
    top: 0%;
    right: 3%;
    background-color: rgba($color: #000000, $alpha: 0.1);
    border: 1px solid rgba($color: #000000, $alpha: 0);
    font-size: 13px;
    padding: 3px 5px;
    border-radius: 5px;
  }
}

.Location p {
  font-size: 16px;
  padding: 8px 0 0px;
}

.Location p.tady {
  font-size: 12px;
  color: #d2ebff;
}

/*日出日落、温度*/
.sunlight {
  width: 86%;
  margin: 40px 7% 20px;
}

/*降雨提醒*/
.remind {
  width: 100%;
  // text-align: center;
  font-size: 14px;
  margin: 0;
  // line-height: 30px;
  // margin-top: 40px;
  // margin-bottom: 0;
}

.beijing {
  width: 100%;
  // background: linear-gradient(rgba(21, 54, 104, 0),
  //     rgba(21, 54, 104, 0.6),
  //     rgba(21, 54, 104, 0.8));
  padding-bottom: 12px;
}

@keyframes example {
  from {
    background-color: red;
  }

  to {
    background-color: yellow;
  }
}

.Tips_box {
  width: 96%;
  margin: 5px auto;
  height: 50px;
  display: flex;
  background-color: rgba($color: #000000, $alpha: 0.2);
  padding: 10px;
  box-sizing: border-box;
  align-items: center;
  overflow: hidden;

  .xiaolb {
    .remind {
      animation-name: example;
      animation-duration: 4s;
    }

    padding: 5px 8px 3px;
    margin-right: 20px;
    border-radius: 5px;
    box-sizing: border-box;

    img {
      width: 22px;
    }

    background-color: rgba($color: #66CBFF, $alpha: 0.5);
  }
}

.yaosu4_box {
  list-style: none;
  display: flex;
  justify-content: space-evenly;

  li {
    position: relative;

    p {
      margin: 0;
    }

    div {
      display: flex;
      align-items: center;
      margin: 5px 0;

      span {
        font-size: 13px;
      }

      img {
        position: absolute;
        left: -25px;
        width: 20px;
      }
    }
  }
}

/*实况五要素*/
.condition {
  width: 96%;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.2);
  margin: 10px 2% 0px;
  padding: 20px 0;
}

.condition li {
  float: left;
  width: calc(100% / 5 - 1px);
  text-align: center;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  font-size: 14px;
}

.condition li:last-child {
  border-right: 0;
}

/*逐3小时预报*/
.black {
  width: 96%;
  background: rgba(0, 0, 0, 0.2);
  // background-color: rgba($color: #139CB6, $alpha: 0.7);
  margin: 0px 2% 0px;
  overflow: hidden;
  padding-bottom: 20px;
  position: relative;
}

.black p.title {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  line-height: 48px;
  font-size: 15px;
  padding-left: 4%;
  margin: 0;
}

.rcril_box {
  // margin-top: 20px;
}

.richuriluo {
  // width: 100%;
  margin: 20px auto 0;
  width: 320px;
  height: 230px;
  color: #fff;
  position: relative;

  .pprTips {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    color: rgba(250, 250, 250, .6);
  }
}

.xiaoshiYB {
  .xzXiaoshiYb {
    color: rgba(202, 227, 224, 0.8);
    border-bottom: 2px solid rgb(202, 227, 224);
  }

  span {
    padding-bottom: 13px;
    margin-right: 20px;
  }
}

.tianYb2 {
  padding: 0;

  ul {
    list-style: none;
    padding: 10px 0;
    display: flex;
  }

  li:first-child {
    border-right: 1px solid rgba($color: #c9c9c9, $alpha: 0.8);
  }

  li {
    display: flex;
    align-items: center;
    justify-content: space-around;

    >div {
      display: inline-block;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }

    width: 50%;
    padding: 0 5px;
    box-sizing: border-box;

    .day_tianqi {
      span {
        margin: 5px 0;
      }

      .kongqi_bg {
        width: 30px;
        font-size: 12px;
        background-color: #00CD50;
        border-radius: 10px;
      }
    }

    .day_yaosu {
      img {
        width: 30px;
        margin-right: 10px;
      }
    }
  }
}

.HourDragDrop {
  width: 100%;
  height: 300px;
  overflow: auto;
}

.hour li {
  width: 12.5%;
  float: left;
  text-align: center;
  background: url(~@/assets/images/yb_4.png) no-repeat bottom 28px center;
  background-size: 100%;
  font-size: 12px;
}

.hour li img.weather {
  width: 28px;
  /*margin-top: 100px;*/
}

.hour li div {
  margin: 2px 0 6px;
}

.hour li div img {
  width: 7px;
  margin: 0 3px;
}

.hour li span.air {
  width: 8px;
  height: 4px;
  background: #e07c13;
  display: inline-block;
  border-radius: 4px;
  float: left;
  margin: 7px 0 0 14%;
}

.hour li p {
  margin-top: 20px;
  color: rgba(255, 255, 255, 0.8);
}

/*六天预报*/
.prediction {
  margin: 5px auto;
}

.prediction li {
  width: 16.6%;
  float: left;
  text-align: center;
  margin-top: 12px;
  font-size: 14px;
}

.prediction li p.date {
  padding: 4px 0 8px;
  color: rgba(255, 255, 255, 0.7);
}

.prediction li img {
  width: 28px;
  display: block;
  margin: 0 auto;
}

.prediction li img.night {
  margin: 300px auto 6px;
}

.prediction li div {
  margin-top: 8px;
}

.prediction li span.color {
  width: 54%;
  height: 4px;
  background: #d4c50e;
  display: inline-block;
  border-radius: 6px;
  float: left;
  margin: 4px 23%;
}

.weaPart1 {
  width: 100%;
  height: 200px;
  text-align: center;
  padding-top: 10px;

  // margin-top: 10px;
  .region-name {
    margin: 0;
    display: flex;
    padding: 0 15px;
    align-items: center;
    justify-content: space-between;
    color: rgba($color: #fff, $alpha: 0.8);

    span {
      font-size: 12px;
    }
  }

  .weatherDiv1 {
    width: 100%;
    height: 100%;
    // padding: 0 50px;
    box-sizing: border-box;
    position: relative;

    .warning{
      position: absolute;
      top: 1%;
      right: 5%;
    }

    .warning_info{
      img{
        width: 100px;
      } 
    }

    .empty_warning{
      font-size: 14px;
      background-color: #66CBFF;
      border-radius: 3px;
      padding: 0px 3px;
      height: 30px;
      display: flex;
      align-items: center;
    }

    .font40 {
      position: relative;
      text-align: left;
      padding-left: 30px;
      font-family: '宋体';

      .weaState {
        font-size: 24px;
        position: absolute;
        left: 10%;
        bottom: 10%;
        white-space: nowrap;
      }

      .wd {
        font-size: 100px;
        margin-bottom: 0;
        position: relative;
      }

      .wddw {
        font-size: 25px;
        margin-bottom: 0;
        position: absolute;
        top: 15%;
      }
    }

    .font40 {
      // font-size: 24px;
      // margin: 0;
    }
  }
}

.weaPart {
  width: 100%;
  height: 230px;
  text-align: center;
}

// #weather {
//   width: 320px;
//   height: 230px;
//   display: inline-block;
//   position: relative;
//   color: #fff;
// }

#c1 {
  /*background:#2371C6;*/
}

.module {
  display: inline-block;
  position: absolute;
}

.left {
  width: 50px;
  top: 185px;
  left: 15px;
}

.right p,
.left p {
  margin: 0;
}

.right {
  width: 50px;
  top: 185px;
  left: 255px;
}

.weather-text {
  width: 200px;
  top: 80px;
  left: 65px;
  font-size: 14px;
  line-height: 25px;
}

.font42 {
  margin-bottom: 0;
}

// .font40 {
//   font-size: 120px;
//   margin-bottom: 0;
//   // line-height: 60px;
// }

#wd {
  width: 100%;
  height: 200px;
}

#yb1,
#yb2 {
  width: 100%;
  height: 300px;
}

#yb1,
#yb2 {
  position: absolute;
}

#yb1 {
  // top: 35%;
  top: 30%;
}

#yb2 {
  // top: 45%;
  top: 40%;
}

.toTop {
  position: absolute;
  bottom: -35%;
  width: 100%;
}

.toTop_box {
  position: relative;

}
</style>